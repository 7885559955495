h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center
}

p {
    text-align: justify;
    text-decoration: none
}

ul {
    list-style: circle
}

::-webkit-scrollbar {
    display: none
}

.abdestTrue {
    color: red;
    letter-spacing: 1.8229166666666667vw;
    text-transform: uppercase;
    width: auto
}

/* @font-face { */
    /* font-display: swap; */
    /* font-family: Poppins; */
    /* font-style: normal; */
    /* font-weight: 400; */
    /* src: url(../../static/media/poppins-devanagari-400-normal.64d5f06ee726edd58ca3.woff2) format("woff2"),url(../../static/media/poppins-all-400-normal.6fbfdac99c274b77fe96.woff) format("woff"); */
    /* unicode-range: u+0900-097f,u+1cd0-1cf6,u+1cf8-1cf9,u+200c-200d,u+20a8,u+20b9,u+25cc,u+a830-a839,u+a8e0-a8fb */
/* } */
/*  */
/* @font-face { */
    /* font-display: swap; */
    /* font-family: Poppins; */
    /* font-style: normal; */
    /* font-weight: 400; */
    /* src: url(../../static/media/poppins-latin-ext-400-normal.4f38b4331448d2313adc.woff2) format("woff2"),url(../../static/media/poppins-all-400-normal.6fbfdac99c274b77fe96.woff) format("woff"); */
    /* unicode-range: u+0100-024f,u+0259,u+1e??,u+2020,u+20a0-20ab,u+20ad-20cf,u+2113,u+2c60-2c7f,u+a720-a7ff */
/* } */
/*  */
/* @font-face { */
    /* font-display: swap; */
    /* font-family: Poppins; */
    /* font-style: normal; */
    /* font-weight: 400; */
    /* src: url(../../static/media/poppins-latin-400-normal.405055dd680fa1dcdfa2.woff2) format("woff2"),url(../../static/media/poppins-all-400-normal.6fbfdac99c274b77fe96.woff) format("woff"); */
    /* unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd */
/* } */
/*  */